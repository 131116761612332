/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import "~antd/dist/reset.css";

body {
  background-color: #f0f2f5 !important;
}

.site-layout .site-layout-background {
  background: #fff !important;
}
.option-outer > div.ant-form-item {
  width: 48% !important;
  margin-right: 6px !important;
}
.options-outer > div.ant-form-item {
  width: 98% !important;
  margin-right: 6px !important;
}
/* .option-outer div:nth-child(3) {
  width: 100% !important;
  margin-right: 15px !important;
} */
.option-outer label,
.options-outer label {
  height: auto !important;
}

img {
  max-width: 100%;
}

/* .ant-form-item {
  margin-bottom: 15px !important;
} */
